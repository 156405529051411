import * as React from "react";
import Logo from "../images/Logo.svg";
import { logo } from "./BlinqLogo.module.scss";

export const BlinqLogo: React.FC = () => {
  return (
    <div>
      <Logo className={logo} />
    </div>
  );
};
