import * as React from "react";
import { Panel } from "./Panel";

export const RecentNotices: React.FC = () => {
  return (
    <Panel>
      <h2>Recent Notices</h2>
      <div>None for now</div>
    </Panel>
  );
};
