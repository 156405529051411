import * as React from "react";
import { text, headline, headlineContainer } from "./Message.module.scss";
import { Panel } from "./Panel";
export const Message: React.FC = () => {
  return (
    <Panel>
      <div className={headlineContainer}>
        <div className={headline}>All systems go 🚀</div>
        {/* <div className={headline}>🔴 Degraded Performance</div> */}
      </div>
      <div>
        <p className={text}>
          This status page monitors <a href="https://blinq.me">blinq.me</a>,
          associated sites and applications.
        </p>
      </div>
    </Panel>
  );
};
