import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import {
  container,
  footer,
  footerContent,
  main,
  header,
  headerContent,
} from "./Layout.module.scss";

export const MainLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const thisYear = new Date().getFullYear();
  return (
    <>
      <header className={header}>
        <div className={headerContent}>
          <a href="https://blinq.me">
            <StaticImage
              src="../../images/blinq_main_logo.png"
              alt="logo"
              placeholder="blurred"
              layout="constrained"
              height={40}
            />
          </a>
          <a href="https://blinq.me">
            <div>Return to Blinq</div>
          </a>
        </div>
      </header>
      <main className={main}>
        <div className={container}>{children}</div>
      </main>
      <footer className={footer}>
        <div>
          {`© ${thisYear} Blinq Technologies Pty Ltd. All Rights Reserved.`}
        </div>
      </footer>
    </>
  );
};
