import * as React from "react";
import type { HeadFC, PageProps } from "gatsby";

// import { ServiceTable } from "../components/ServiceTable";
// import { loadServices } from "../utilities/service-loader";
import { BlinqLogo } from "../components/BlinqLogo";
import { MainLayout } from "../components/layouts/MainLayout";
import { Message } from "../components/Message";
import { RecentNotices } from "../components/RecentNotices";

const IndexPage: React.FC<PageProps> = () => {
  // const [services, setServices] = React.useState<Service[]>();
  // React.useEffect(() => {
  //   loadServices()
  //     .then(setServices)
  //     .catch((e) => {
  //       throw new Error(e);
  //     });
  // }, [setServices]);

  // if (!services) {
  //   return (
  //     <MainLayout>
  //       <div>Loading</div>
  //     </MainLayout>
  //   );
  // }

  return (
    <MainLayout>
      <h1>
        Blinq Status
        <br />
      </h1>
      <Message />
      <RecentNotices />
      {/* <ServiceTable services={services} /> */}
      <BlinqLogo />
    </MainLayout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <title>Blinq Status</title>;
